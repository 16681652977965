/* width */
::-webkit-scrollbar {
    width: 7px;
    height: 7px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #aaa;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #999;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:active {
    background: #888;
}

a {
    /*color: inherit;*/
    text-decoration: inherit;
    outline: none;
}

a:focus {
    outline: none;
    -webkit-appearance: none;

}


.grid {
    display: grid;
    grid-template-rows: 100%;
    height: calc(100VH - 50px);
}

.gutter-row {
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAeCAYAAADkftS9AAAAIklEQVQoU2M4c+bMfxAGAgYYmwGrIIiDjrELjpo5aiZeMwF+yNnOs5KSvgAAAABJRU5ErkJggg==');
    background-color: #eee;
    background-repeat: no-repeat;
    background-position: 50%;
    grid-column: 1/-1;
    cursor: row-resize;
}

.gutter-row-1 {
    grid-row: 2;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.hide-scroll::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hide-scroll {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

li.content {
    list-style: none;
    /*font-size: 20px;*/
}

li.content:before {
    content: "\002022";
    font-size: 1rem;
    float: left;
    margin-left: -0.875rem;
}

a{
    color: #0b57d0;
}